import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { FONT, GAEA_COLOURS, styled, UNIT } from "@dexibit/styles";
const StyledMegaModeHeader = styled("div") `
  position: sticky;
  top: 0;
  background-color: ${GAEA_COLOURS.UTILITY.RED.RED};
  width: 100%;
  z-index: 99999;
  color: white;
  text-align: center;
  padding: ${UNIT.small};
  font-size: ${FONT.HEADING_SMALL};
  box-sizing: border-box;
`;
export const MEGA_ADMIN_ACCOUNT_ID = 2;
export const MegaModeHeader = () => {
    return _jsx(StyledMegaModeHeader, { children: "Impersonating Dexibit mega account" });
};
